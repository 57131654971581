import $ from 'jquery';
import intlTelInput from 'intl-tel-input';
import getScriptPath from '../utils';
import { getParams } from '../utils/url';
import validator from './validator';

export const initAutoValidation = () => {
  if ($('[data-autovalidate]').length) {
    validator();
  }
};

export const initAutoPopulateFields = () => {
  // auto populate a form field with a value passed in a query param
  $('[data-autopopulate]').each((index, elem) => {
    const el = $(elem);
    let param = el.attr('data-autopopulate');
    if (!param || param === 'true' || param === '1') {
      param = 'source';
    }
    const value = getParams()[param] || '';
    el.val(value);
  });
};

export const intlTelephone = (scriptPath) => {
  if ($('input[type="tel"]').length) {
    const telInput = document.querySelector('input[type="tel"]');

    intlTelInput(telInput, {
      separateDialCode: true,
      utilsScript: [scriptPath, 'tel-utils.js'].filter(Boolean).join('/'),
    });
  }
};

const eloquaForm = () => {
  if ($('#requestACallbackForm').length || $('#emailUsForm').length) {
    $('select[name="existingCustomer"]').on('change', (evt) => {
      const existingCustomer = $(evt.currentTarget).val();

      if (existingCustomer === 'Yes') {
        $('input[name="policyNumber"]').closest('.input-holder').toggleClass('d-none', false);
        $('[data-listen-element-option="Yes"]').toggleClass('d-none', false);
        $('[data-listen-element-option="No"]').toggleClass('d-none', true);
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').removeAttr('required');
      } else {
        $('input[name="policyNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('[data-listen-element-option="Yes"]').toggleClass('d-none', true);
        $('[data-listen-element-option="No"]').toggleClass('d-none', false);
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').prop('required', true);
      }
    });

    $(document).on('change', 'select[name="helpDropdown"]', (evt) => {
      const helpDropdown = $(evt.currentTarget).val();
      if (helpDropdown === 'Existing quote') {
        $('input[name="quoteRefNumber"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').removeAttr('required');
      } else {
        $('input[name="quoteRefNumber"]').closest('.input-holder').toggleClass('d-none', true);
        $('select[name="whereLiving"]').closest('.input-holder').toggleClass('d-none', false);
        $('select[name="whereLiving"]').prop('required', true);
      }
    });
  }
};

export default () => {
  const scriptPath = getScriptPath(document.currentScript.src);

  initAutoPopulateFields();
  initAutoValidation();
  intlTelephone(scriptPath);
  eloquaForm();
};
