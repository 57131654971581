import $ from 'jquery';
import config from '../../config';
import renderTemplate from '../../utils/template';
import Pagination from '../pagination';
import filterFlagTpl from './views/filter-flag.tpl.html';
import templatePagination from './views/pagination.tpl.html';

const componentsSvg = `${config.svgPath}/component-sprite.svg`;
const controller = $('[data-filter-controller="posts"]');
const filterInput = $('[data-filter-input="checkbox"]');
const htmlLoad = $('[data-html-load="posts"]');
const initialPagination = renderTemplate(templatePagination);
const defaultContentHTML = htmlLoad.length > 0 ? htmlLoad.html() : '';
let jsonData = [];
let filteredJsonData = [];
const limit = 8;
let filters = [];

const pagingHandler = (currentPage) => {
  const start = (currentPage - 1) * limit;
  const end = start + limit;
  htmlLoad.empty();
  Array.apply([], filteredJsonData)
    .slice(start, end)
    .forEach((article) => {
      htmlLoad.append(article);
    });
};

const createFilterTags = () => {
  const filterTagsChosen = filters.filter((a, b) => filters.indexOf(a) === b);
  const html = renderTemplate(filterFlagTpl, {
    filters: filterTagsChosen,
    componentsSvg,
  });
  $('#filterFlagContainer').empty().append(html);
};

const reset = () => {
  filters = [];
  if ($('[data-dropdown-collapse-label]').length) {
    $(`[data-dropdown-collapse-label]`).text(
      $(`[data-dropdown-collapse-label]`).data('dropdown-collapse-label')
    );
    $(`[data-dropdown-collapse-label]`).addClass('dropdown-collapse__placeholder');
  } else {
    controller.find('input[type="checkbox"]').prop('checked', false);
  }
  filteredJsonData = jsonData;
  htmlLoad.html(defaultContentHTML);
  // Update Pagination
  Pagination({
    totalItems: jsonData.length,
    itemsPerPage: limit,
  });
  createFilterTags();
};

const filterHandler = () => {
  filteredJsonData = [];
  filters = [];
  if (filterInput.find('input[type="checkbox"]:checked').length > 0) {
    filterInput.find('input[type="checkbox"]:checked').each((i, el) => {
      const checkedValue = $(el).val();

      const filtered = jsonData.filter(
        (index, entry) => $(entry).attr('data-post-tags').indexOf(checkedValue) !== -1
      );

      $.merge(filteredJsonData, filtered).sort().reverse();

      filters.push(checkedValue);
    });

    const start = 0;
    const end = limit;
    htmlLoad.empty();
    Array.apply([], filteredJsonData)
      .slice(start, end)
      .forEach((article) => {
        htmlLoad.append(article);
      });

    // Update Pagination
    Pagination({
      totalItems: filteredJsonData.length,
      itemsPerPage: limit,
    });
    createFilterTags();
  } else reset();
};

const filterDropdownHandler = (value) => {
  filteredJsonData = [];
  filters = [];
  const filtered = jsonData.filter(
    (index, entry) => $(entry).attr('data-post-tags').indexOf(value) !== -1
  );

  $.merge(filteredJsonData, filtered).sort().reverse();

  filters.push(value);

  const start = 0;
  const end = limit;
  htmlLoad.empty();
  Array.apply([], filteredJsonData)
    .slice(start, end)
    .forEach((article) => {
      htmlLoad.append(article);
    });

  // Update Pagination
  Pagination({
    totalItems: filteredJsonData.length,
    itemsPerPage: limit,
  });
  createFilterTags();
};

const init = () => {
  $(document).on('click', '[data-filter-button] button', reset);
  $(document).on('click', '[data-filter-input="checkbox"] input[type="checkbox"]', (e) => {
    const $this = $(e.currentTarget);
    const identicalCheckbox = $(`input[type="checkbox"][value="${$this.val()}"]`);
    identicalCheckbox.each((i, el) => $(el).prop('checked', $this.prop('checked')));
    filterHandler();
  });
  $(document).on('click', '[data-filter-input="dropdown"]', (e) => {
    e.preventDefault();
    const collaspeId = $(e.currentTarget).parent().attr('id');
    const value = $(e.currentTarget).text();

    $(`[data-target="#${collaspeId}"]`).text(value);
    $(`[data-target="#${collaspeId}"]`).removeClass('dropdown-collapse__placeholder');
    $(`#${collaspeId}`).collapse('hide');
    filterDropdownHandler(value);
  });

  jsonData = $('.post__item');
  filteredJsonData = jsonData;
  $('.post[article-dynamic-data]').after(initialPagination);

  Pagination({
    totalItems: jsonData.length,
    itemsPerPage: limit,
    onChange: (page) => {
      pagingHandler(page);
    },
  });
  pagingHandler('1');
};

export default () => {
  // eslint-disable-next-line
  if ($('[data-filter-controller="posts"]').length && $('[data-html-load="posts"]').length) {
    init();
    $('#filterFlagContainer').on('click', '.filter-flag__icon', (e) => {
      const flagToUncheck = $(e.currentTarget).parent().attr('data-flag-label');
      $('[data-filter-input="checkbox"]')
        .find(`input[type="checkbox"][value="${flagToUncheck}"]:checked`)
        .prop('checked', false);
      filterHandler();
    });
  }
};
