import $ from 'jquery';
import config from './config';
import renderTemplate from './utils/template';
import svgTemplate from './views/svg.tpl.html';

let isExternalLinkFunc;

const componentsSvg = `${config.svgPath}/component-sprite.svg`;
const icons = {
  action: 'chevron-circle-right',
  external: 'external-link',
  secure: 'lock',
};
const engLabels = {
  external: 'external site',
  secure: 'secure site',
};
const esLabels = {
  external: 'sitio externo',
  secure: 'sitio seguro',
};
const hidden = {
  action: 'true',
  external: 'false',
  secure: 'false',
};

// types external, secure, action
const addLinkSVG = (link, type) => {
  // eslint-disable-next-line no-restricted-globals
  const svgLabels = location.href.includes('es-us') ? esLabels : engLabels;
  const svg = renderTemplate(svgTemplate, {
    cls: type,
    svgSpritePath: componentsSvg,
    icon: icons[type],
    label: svgLabels[type],
    hidden: hidden[type],
  });
  // TODO: is this reliable? Check if text-nowrap already exists?
  const words = link.html().split(/\s+/);
  const lastWord = words.pop();
  const firstPart = words.join(' ');

  // Checks if the icon is already present and not a button
  if (link.find(`svg.${type}`).length === 0) {
    if (link.hasClass('featured-topic') || link.hasClass('btn')) {
      link.append(svg);
    } else if (link.find(`svg.${type}`).length === 0) {
      if (link.find('span.text-md-nowrap').length === 0) {
        link.html(`${firstPart} <span class="text-md-nowrap">${lastWord}${svg}</span>`);
      } else {
        link.find('span.text-md-nowrap').append(svg);
      }
    }
  }
};

// eslint-disable-next-line no-unused-vars
export const isExternal = (link, href, hostname) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  $.trim(link.text()).length && href.includes('//'); // is not an icon or image // not internal

// Security concern: https://mathiasbynens.github.io/rel-noopener/ for target="_blank"
const sanatizeTargetBlank = (rel) => {
  const parts = new Set((rel || '').split(' '));
  parts.add('noopener');
  parts.add('noreferrer');
  return [...parts].join(' ').trim();
};

export const initActionLinks = () => {
  $('a.link-action').each((index, el) => addLinkSVG($(el), 'action'));
};

export const initBlankLinks = () => {
  $('[target=_blank]').each((index, el) => {
    // eslint-disable-next-line no-param-reassign
    el = $(el);
    el.attr('rel', sanatizeTargetBlank(el.attr('rel')));
  });
};

export const initExternalLinks = (addIcon = true) => {
  if (!isExternalLinkFunc) {
    isExternalLinkFunc = isExternal;
  }

  $('a:not(.link-external)').each((index, el) => {
    // eslint-disable-next-line no-param-reassign
    el = $(el);
    const href = el.attr('href') || '';
    const { hostname } = el.get(0);
    if (isExternalLinkFunc(el, href, hostname)) {
      el.addClass('link-external');
    }
  });

  $('a.link-external').each((index, el) => {
    // eslint-disable-next-line no-param-reassign
    el = $(el);
    el.attr('target', '_blank');
    if (addIcon) {
      addLinkSVG(el, 'external');
    }
  });

  initBlankLinks();
};

export const initSecureLinks = () => {
  $('a.link-secure').each((index, el) => addLinkSVG($(el), 'secure'));
};

export const setExternalLinkChecker = (isExternalFunc) => {
  isExternalLinkFunc = isExternalFunc;
};

export const initPdfLinks = () => {
  $('a[href*=".pdf"]').each((index, el) => {
    if (el.text !== '' && el.text.indexOf('[PDF]') < 0) {
      $(el).append(' [PDF]');
    }
  });
};

export default () => {
  initPdfLinks();
  initExternalLinks();
  initSecureLinks();
  initActionLinks();
};
