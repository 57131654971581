import $ from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';

const openDropdown = (evt) => {
  const navItem = evt.currentTarget;
  const dropDown = $(navItem).siblings('.dropdown-menu');
  if ($(dropDown).is(':hidden')) {
    $(navItem).dropdown('toggle');
  }
};

export default () => {
  let hoverTimeout;
  const delay = $('.header-meganav').attr('data-delay');
  $('.main-navigation .nav-link[data-toggle="dropdown"]')
    .focus((evt) => {
      hoverTimeout = setTimeout(() => {
        openDropdown(evt);
      }, delay);
    })
    .hover((evt) => {
      evt.currentTarget.focus();
    })
    .focusout(() => {
      clearTimeout(hoverTimeout);
    })
    .mouseout(() => {
      clearTimeout(hoverTimeout);
    })
    .click((evt) => {
      const element = evt.currentTarget;
      // eslint-disable-next-line
      location.assign(encodeURI($(element).attr('href')));
    });

  $('.inner-menu-row').click((evt) => {
    evt.stopPropagation();
  });

  $('[data-toggle="tab"]').click((evt) => {
    const tab = evt.currentTarget;
    $(tab).tab('show');
  });

  $('.inner-menu .btn-close').click((evt) => {
    const close = evt.currentTarget;
    $(close).parents('.dropdown-menu').dropdown('hide');
  });
};
