/* eslint-disable import/prefer-default-export */
export const generateServiceReferenceId = () => {
  const part1 = 1000000000 + Math.floor(Math.random() * 9000000000);
  const part2 = 100000000 + Math.floor(Math.random() * 900000000);
  return `${part1}-${part2}`;
};

export const XSS = {
  regex: /(<([^>]+)>)|(alert\(")|(\"\))/ig, // eslint-disable-line
  is(str) {
    return this.regex.test(decodeURIComponent(str));
  },
  sanitize(input) {
    return input.replace(this.regex, '');
  },
};
