import $ from 'jquery';

export default () => {
  let prevValue = null;
  $(document).on('blur', '[data-js-autocomplete="country"]', (evt) => {
    evt.preventDefault();
    setTimeout(() => {
      if (prevValue !== evt.target.value) {
        const DCRs = JSON.parse($('#dcr-json-data').html());
        $('#demo-redirect-link').html(`<strong>${DCRs[evt.target.value]}</strong>`);
        prevValue = evt.target.value;
      }
    }, 200);
  });
};
