import $ from 'jquery';
import { cookie } from '../utils/storage';

export default () => {
  const activeElem = 'block-selection__item--active';
  const checkElem = '[data-checkbox-target="manage-member-policy"]';
  const selectElem = '[data-select-target="manage-member-policy"]';
  const cookieName = 'manage-member-policy';
  const updateBlockSelection = (value) => {
    const isCheckBoxSelected = $(`[data-checkbox="${cookieName}"]:checked`).length > 0;
    if (isCheckBoxSelected) {
      cookie.set(cookieName, value, { expires: 30 });
    }
    $(checkElem).removeClass(activeElem);
    $(`[data-checkbox-value="${value}"]`).addClass(activeElem);
    $(selectElem).val(value);
    $('[data-block-selection-content]').hide();
    if ($(`[data-block-selection-content="${value}"]`).length) {
      $(`[data-block-selection-content="${value}"]`).show();
    } else {
      $(`[data-block-selection-content]`).show();
    }
  };

  $(document.body).on('click', checkElem, (evt) => {
    evt.preventDefault();
    const value = $(evt.currentTarget).attr('data-checkbox-value');
    updateBlockSelection(value);
  });

  $(document.body).on('change', selectElem, (evt) => {
    const value = $(evt.currentTarget).val();
    updateBlockSelection(value);
  });

  $(document.body).on('click', `[data-checkbox="${cookieName}"]`, () => {
    if ($(`[data-checkbox="${cookieName}"]:checked`).length > 0) {
      if ($(`.${activeElem}`).length > 0) {
        const value = $(`.${activeElem}`).attr('data-checkbox-value');
        cookie.set(cookieName, value, { expires: 30 });
      }
    } else {
      cookie.remove(cookieName);
    }
  });

  if ($(checkElem).length) {
    const value = cookie.get(cookieName);
    if (typeof value !== 'undefined') {
      $(`[data-checkbox="${cookieName}"]`).prop('checked', true);
      $(`[data-checkbox-value="${value}"]`).addClass(activeElem);
      $(selectElem).val(value);
      if ($(`[data-block-selection-content="${value}"]`).length) {
        $(`[data-block-selection-content="${value}"]`).show();
      } else {
        $(`[data-block-selection-content]`).show();
      }
    }
  }
};
